<template>
  <svg
    class="v-ui-vertical-line"
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="28"
    viewBox="0 0 2 28"
    fill="none"
  >
    <path
      opacity="0.6"
      d="M1 1L1 27"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<style lang="scss">
@use 'assets/variables';

.v-ui-vertical-line {
  stroke-width: 2px;
  stroke: variables.$White;
  opacity: 0.6;
}
</style>
