<template>
  <template v-if="isExtraSmall">
    <header-parts-basket-button-mobile-calanhad
      v-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Calanhad'"
      :to-pay-with-money="toPayWithMoney"
      :to-pay-with-points="toPayWithPoints"
      :small-basket-data-test-id="smallBasketDataTestId"
      :content-length="contentLength"
      @click="emit('click')"
    />
    <header-parts-basket-button-mobile-default
      v-else
      :to-pay-with-money="toPayWithMoney"
      :to-pay-with-points="toPayWithPoints"
      :small-basket-data-test-id="smallBasketDataTestId"
      :content-length="contentLength"
      @click="emit('click')"
    />
  </template>
  <div
    v-else
    class="v-h-100 v-d-flex v-justify-content-center v-align-items-center"
  >
    <header-parts-basket-button-barad-nimras
      v-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'"
      :to-pay-with-money="toPayWithMoney"
      :to-pay-with-points="toPayWithPoints"
      :small-basket-data-test-id="smallBasketDataTestId"
      :content-length="contentLength"
      @click="emit('click')"
    />
    <header-parts-basket-button-default
      v-else
      :to-pay-with-money="toPayWithMoney"
      :to-pay-with-points="toPayWithPoints"
      :small-basket-data-test-id="smallBasketDataTestId"
      :content-length="contentLength"
      @click="emit('click')"
    />
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { useWindowSize } from '@arora/common'

import { PositionType } from '~api/consts'

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()

const clientStore = useClientStore()

const { isExtraSmall } = useWindowSize()

const toPayWithMoney = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.WithDiscount ?? 0
)

const toPayWithPoints = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithPoints?.WithDiscount ?? 0
)

const smallBasketDataTestId = computed<string>(() => {
  return `small-basket-button${appConfig.RestaurantSettingsPreRun.OneStageCart ? '-one-stage' : '-two-stage'}`
})

const contentLength = computed<number>(() => {
  const result: CartItem[] | undefined = clientStore.ClientState?.data?.Cart?.Content

  if (!result) return 0

  return result.filter((ci: CartItem) => ci.PositionType !== PositionType.Additional)
    .length
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-header-button {
  @include mixins.trans;
  padding: 0.5rem 1.1rem;

  background-color: variables.$SmallBasketBackgroundColor;
  color: variables.$SmallBasketColor;

  border-radius: variables.$BorderRadiusButton;

  min-height: 42px;
  width: fit-content;
  min-width: 65%;
  max-width: 100%;

  margin: auto 0 auto auto;

  &:hover {
    background: variables.$SmallBasketBackgroundColorVariant;
    opacity: 0.75;
  }

  &--hollow {
    background-color: variables.$HeaderBackgroundColor;
    color: variables.$SmallBasketColor;
    border: 3px solid variables.$SmallBasketBackgroundColor;
    width: fit-content;

    &:hover {
      background: variables.$HeaderBackgroundColor;
      border-color: variables.$SmallBasketBackgroundColorVariant;
    }

    .v-badge-root > .v-badge {
      font-weight: 600;
      border-width: 3px;
    }

    .v-header-button__icon {
      path {
        stroke: variables.$SmallBasketBackgroundColor;
      }
    }
  }

  &__barad-nimras {
    height: 80px;
    width: 80px;
    min-width: fit-content;
    padding: 0.5rem;

    .v-header-button__icon {
      width: 40px;
      height: 40px;
    }
  }

  &__left {
    border-right: 1.5px solid variables.$PrimaryColorOpaq50;
    padding-right: 1rem;
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: none;

    path {
      stroke: variables.$SmallBasketColor;
    }
  }
}
</style>
