<template>
  <div
    class="v-main-menu-link v-footer-basket v-pointer"
    :class="{
      'v-active': inCart
    }"
    :aria-label="translate('footerPage.cart')"
    :data-test-id="smallBasketDataTestId"
    @click="emit('click')"
  >
    <div class="v-bar--icon">
      <icon-old-menu-hand-bag />
      <cart-small-basket-counter />
    </div>
    <div class="v-bar--text">
      <span v-html="translate('footerPage.cart')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()

const { translate } = useI18nSanitized()
const { isLinkContains } = useUrl()

const inCart = computed<boolean>(
  () =>
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartFirstStep) ||
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartSecondStep)
)
</script>
