<template>
  <div
    class="v-header-button--default v-pointer"
    :data-test-id="smallBasketDataTestId"
    @click="emit('click')"
  >
    <div>
      <span
        v-if="appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount'"
        v-html="translate('smallBasket.cart')"
      />
      <common-badge
        v-else-if="appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default'"
        invert-colors
      >
        <template #content>
          <ui-icon
            disable-hover
            color="white"
            :size="24"
            name="basketHeader"
          />
        </template>
        <template #badge>
          {{ contentLength }}
        </template>
      </common-badge>
      <ui-icon
        v-else
        disable-hover
        color="white"
        :size="24"
        name="basketHeader"
      />
    </div>
    <ui-vertical-line />
    <div
      v-if="
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'money'
      "
    >
      <common-currency
        v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
        show-points
        :amount="toPayWithPoints"
      />
      <common-currency
        v-else
        :amount="toPayWithMoney"
      />
    </div>
    <span
      v-else
      class="v-ml-sm"
      v-html="contentLength"
    />
  </div>
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()

const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-header-button--default {
  @include mixins.trans;
  background-color: variables.$SmallBasketBackgroundColor;
  color: variables.$SmallBasketColor;

  border-radius: variables.$BorderRadiusInput;

  display: flex;
  width: auto;
  padding: 0.5rem 1.145rem;
  justify-content: center;
  align-items: center;
  gap: 0.85rem;
  flex-shrink: 0;

  &:hover {
    background: variables.$PrimaryBackgroundColorHover;
    color: variables.$PrimaryColor;
  }

  &:focus,
  &:active,
  &:visited {
    background: variables.$PrimaryBackgroundColorPressed;
    color: variables.$PrimaryColor;
  }
}
</style>
