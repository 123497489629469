<template>
  <div
    class="v-header-button--barad-nimras v-mb-sm v-d-flex v-align-items-center v-pointer v-justify-content-center"
    :class="{
      'v-header-button--barad-nimras--hollow':
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default'
    }"
    :style="nonSticky ? 'flex-direction: column' : 'flex-direction: row; gap: 6px'"
    :data-test-id="smallBasketDataTestId"
    @click="emit('click')"
    v-sticky-top="{ refresh: false, onRefresh: updateSticky }"
  >
    <div>
      <common-badge
        v-if="
          appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
          appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount'
        "
        :invert-colors="
          appConfig.VueSettingsPreRun.SmallBasketShowMode !== 'default'
        "
      >
        <template #content>
          <ui-icon
            class="v-header-button--barad-nimras__icon"
            disable-hover
            color="currentColor"
            :size="42"
            name="basketHeader"
          />
        </template>
        <template #badge>
          {{ contentLength }}
        </template>
      </common-badge>

      <ui-icon
        v-else
        class="v-header-button--barad-nimras__icon"
        disable-hover
        color="currentColor"
        :size="42"
        name="basketHeader"
      />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'money'
      "
    >
      <common-currency
        v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
        show-points
        :amount="toPayWithPoints"
      />
      <common-currency
        v-else
        :amount="toPayWithMoney"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

import { vStickyTop } from '@arora/common'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()

const nonSticky = ref<boolean>(true)

let currentSticky = false
function updateSticky(isSticky: boolean): void {
  if (isSticky !== currentSticky) {
    currentSticky = isSticky
    nonSticky.value = !isSticky
  }
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-header-button--barad-nimras {
  @include mixins.trans;
  background-color: variables.$SmallBasketBackgroundColor;
  color: variables.$SmallBasketColor;

  border-radius: variables.$BorderRadiusButton;

  min-height: 42px;
  max-width: 100%;

  height: 80px;
  width: 80px;
  min-width: fit-content;
  padding: 0.5rem;
  text-align: center;

  .v-header-button--barad-nimras__icon {
    width: 40px;
    height: 40px;
  }

  margin: auto 0 auto auto;

  &:hover {
    background: variables.$SmallBasketBackgroundColorVariant;
    opacity: 0.75;
  }

  &--hollow {
    background-color: variables.$HeaderBackgroundColor;
    color: variables.$SmallBasketColor;
    border: 3px solid variables.$SmallBasketBackgroundColor;
    width: fit-content;

    &:hover {
      background: variables.$HeaderBackgroundColor;
      border-color: variables.$SmallBasketBackgroundColorVariant;
    }

    .v-badge-root > .v-badge {
      font-weight: 600;
      border-width: 3px;
    }

    .v-header-button--barad-nimras__icon {
      color: variables.$SmallBasketBackgroundColor;
    }

    .v-currency-wrapper {
      color: variables.$SmallBasketBackgroundColor;
    }
  }

  &__left {
    border-right: 1.5px solid variables.$PrimaryColorOpaq50;
    padding-right: 1rem;
  }
}
</style>
