<i18n>
ru:
  cart: 'Корзина: {num}'
ua:
  cart: 'Кошик: {num}'
us:
  cart: 'Cart: {num}'
</i18n>

<template>
  <AnimatePresence>
    <motion.div
      v-if="notInCart && totalAmountWithoutRemoved > 0"
      key="mobile-basket-button"
      class="v-basket-button-mobile v-d-flex v-justify-content-center v-align-items-center"
      :initial="{ left: 250, right: 250 }"
      :animate="{ left: 16, right: 16 }"
      :exit="{ left: 250, right: 250 }"
      :transition="{
        duration: 0.3,
        delay: 0.5
      }"
    >
      <div class="v-w-100">
        <ui-button
          :click-event="cartClick"
          :label="
            translate('basketButtonMobileCalanhad.cart', {
              num: totalAmountWithoutRemoved
            })
          "
          color="primary"
          type="fill"
          width="wide"
          :size="48"
        >
          <motion.div
            key="mobile-basket-button-basket-caption"
            class="v-d-flex v-justify-content-between v-w-100 v-basket-button-mobile__basket-caption"
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :exit="{ opacity: 0 }"
            :transition="{
              duration: 0.3,
              delay: 0.7
            }"
          >
            <span
              v-html="
                translate('basketButtonMobileCalanhad.cart', {
                  num: totalAmountWithoutRemoved
                })
              "
            />

            <common-currency
              :amount="clientStore.ClientState.data?.FinalCalculatePrice ?? 0"
            />
          </motion.div>
        </ui-button>
      </div>
    </motion.div>
  </AnimatePresence>
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

import { motion } from 'motion-v'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})
const appConfig = useAppConfig()

const clientStore = useClientStore()

const { translate } = useI18nSanitized()
const { isLinkContains } = useUrl()
const { totalAmountWithoutRemoved } = useCart()

async function cartClick(): Promise<void> {
  emit('click')
}

const notInCart = computed<boolean>(
  () =>
    !isLinkContains(appConfig.VueSettingsPreRun.Links.CartFirstStep) &&
    !isLinkContains(appConfig.VueSettingsPreRun.Links.CartSecondStep)
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-basket-button-mobile {
  position: fixed;
  box-shadow: variables.$FloatShadow;
  bottom: 16px;
  left: 250px;
  right: 250px;
  z-index: 1000;
  overflow: hidden;

  &__basket-caption {
    opacity: 0;
  }
}
</style>
